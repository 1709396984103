<template>
    <div class="cr-select" :class="selectClasses" v-click-outside="() => (isOpen = false)">
        <div class="cr-select__inner" @click="toggle">
            <div class="label__wrapper">
                <div class="cr-select__icon" v-if="$slots['icon']">
                    <slot name="icon" />
                </div>
                <p :class="{ placeholder: !selected && !infinitySelect }">
                    {{ labelTag }}
                </p>
            </div>
            <ArrowSvg class="arrow-icon" :class="{ active: isOpen }" />
        </div>
        <div class="cr-select__content" :class="{ opened: isOpen }">
            <div class="input__wrapper" v-if="withSearch">
                <LoupeSVG class="loupe" />
                <input
                    type="text"
                    class="input"
                    @input="$emit('update:modelValue', $event.target.value)"
                    @focus="$emit('focus', true)"
                    @blur="$emit('blur', false)"
                />
            </div>
            <div v-if="!infinitySelect">
                <p v-for="item in filteredData" :key="item.value" class="option" @click="selectItem(item)">
                    {{ item.label }}
                </p>
            </div>
            <div v-else>
                <p v-for="item in data" :key="item.value" class="option" @click="selectItem(item)">
                    {{ item.label }}
                </p>
            </div>
            <VueEternalLoading v-if="infinitySelect" :load="load" :is-initial="isInitial">
                <template #loading>
                    <div class="loading">
                        <Vue3Lottie :animationData="SpinPrimary" class="animation-icon" ref="lottie" />
                    </div>
                </template>

                <template #no-results>
                    <div class="my-no-result">{{ $t('common.noResults') }}</div>
                </template>

                <template #no-more>
                    <div class="my-no-more"></div>
                </template>
            </VueEternalLoading>
        </div>
    </div>
</template>

<script>
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';
    import vClickOutside from 'click-outside-vue3';
    import LoupeSVG from '@/assets/icons/buttons/loupe.svg?inline';
    import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';
    import { Vue3Lottie } from 'vue3-lottie';
    import SpinPrimary from '@/assets/animations/spin-primary.json';

    export default {
        name: 'CRSelect',
        components: { ArrowSvg, LoupeSVG, VueEternalLoading, Vue3Lottie },
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            selected: {
                type: [String, Number],
                default: 0,
            },
            placeholder: {
                type: String,
                default: 'Select',
            },
            withSearch: {
                type: Boolean,
                default: false,
            },
            maxHeight: {
                type: String,
                default: 'none',
            },
            infinitySelect: {
                type: Boolean,
                default: false,
            },
            load: {
                type: Function,
                default: () => {},
            },
            label: {
                type: String,
                default: '',
            },
            isInitial: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue', 'focus', 'blur', 'select', 'openState'],
        directives: {
            clickOutside: vClickOutside.directive,
        },
        data() {
            return {
                isOpen: false,
                filteredData: this.data,
                SpinPrimary,
            };
        },
        watch: {
            'data.length': {
                handler(value) {
                    this.filteredData = this.data.filter((item) => item.label !== value);
                },
                immediate: true,
            },
            isOpen(value) {
                this.$emit('openState', value);
                const lottie = this.$refs.lottie;
                if (lottie) value ? lottie.play() : lottie.pause();
            },
        },
        computed: {
            labelTag() {
                const selectedItem = this.data.find((item) => item.value === this.selected);

                if (this.infinitySelect) {
                    return this.label ? this.label : this.placeholder;
                }

                return selectedItem ? selectedItem.label : this.placeholder;
            },
            selectClasses() {
                return [this.isOpen && `wrapper-opened`, this.withSearch && `cr-select_search`];
            },
            contentMaxHeight() {
                return `${this.maxHeight}px`;
            },
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },

            selectItem(item) {
                this.$emit('select', item);
                this.isOpen = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cr-select {
        width: 130px;

        background: $white;
        box-shadow: $box-shadow-dark;
        border-radius: $br-20;

        padding: 6px 13px;
        padding-right: 40px;

        cursor: pointer;

        position: relative;

        &.wrapper-opened {
            box-shadow: none;

            border-bottom-left-radius: $br-0;
            border-bottom-right-radius: $br-0;

            outline: 1px solid $grey-line;
            border-bottom: none;
        }

        .label__wrapper {
            overflow: hidden;
        }

        p {
            width: 100%;
            font-weight: 700;
            font-size: $font-14;

            overflow: hidden;
            text-overflow: ellipsis;

            white-space: nowrap;

            &.placeholder {
                color: $grey-form-label;
            }
        }

        &__inner {
            @include row-align-center-justify-between;

            width: 100%;

            div {
                @include row-align-center;
            }

            .arrow-icon {
                position: absolute;
                right: 13px;

                transition: all 0.2s linear;

                &:deep() {
                    path {
                        stroke: $black;
                        fill: $black;
                    }
                }

                &.active {
                    transform: rotate(180deg);
                }
            }

            .cr-select__icon {
                margin-right: 6px;
            }
        }

        .cr-select__content {
            min-width: 130px;
            width: calc(100% + 2px);

            max-height: v-bind(contentMaxHeight);
            display: none;

            overflow-y: scroll;

            position: absolute;

            z-index: $index-200;

            left: -1px;
            top: 32px;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                margin-bottom: 15px;
            }

            &::-webkit-scrollbar-thumb {
                background: $grey-modal-bg;
                border-radius: $br-20;
            }

            .option {
                text-align: start;

                padding: 6px 13px 6px 13px;

                &:last-child {
                    border-bottom-left-radius: $br-20;
                    border-bottom-right-radius: $br-20;
                }
            }

            &.opened {
                display: block;

                background: $white;
                box-shadow: none;
                border-radius: $br-20;

                border-top-left-radius: $br-0;
                border-top-right-radius: $br-0;

                border: 1px solid $grey-line;
                border-top: none;
            }

            .my-no-result {
                text-align: center;

                padding-bottom: 10px;

                font-style: italic;
                font-size: $font-14;
            }
        }
    }

    // withSearch
    .cr-select_search {
        width: 280px;

        .input__wrapper {
            position: relative;
            padding: 10px;

            .input {
                padding: 6px 16px 6px 38px;

                width: 100%;

                border: 2px solid $grey-form;
                border-radius: $br-20;

                outline: none;
            }

            .loupe {
                position: absolute;
                top: 18px;
                left: 20px;
            }
        }

        .cr-select__content {
            min-width: 280px;
            max-height: v-bind(contentMaxHeight);
        }
    }

    // animation-icon

    .loading {
        @include row-align-center-justify-center;
    }

    .animation-icon {
        width: 80px;
        height: 80px;
    }
</style>
